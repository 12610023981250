<script>
export default {
    name: 'index'
};
</script>

<template>
    <router-view />
</template>

<style lang="scss" scoped></style>
